import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import styled from 'styled-components';

interface ProgressCountProps {
  total: number;
  count: number;
}

const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => (
  <TextStyled>
    <span>{count}</span>/{total}
  </TextStyled>
);

export default ProgressCount;

const TextStyled = styled(Text)`
  color: #777;
  font-feature-settings: 'liga' off, 'clig' off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.021rem;
  span {
    color: #3fa134;
    font-weight: 700;
  }
`;
