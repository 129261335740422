import React, { FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import { Loader, RadioInput, Text } from 'components';
import { PAYMENT_METHOD } from './KiloPayments';
import PaymentMethodsIcon from 'assets/icons/payments/credit-debit-cards.svg';
import PaypalIcon from 'assets/icons/paypal-image.svg';
import PaypalButtonIcon from 'assets/icons/payments/paypyal-button.svg';
import { mobile, tablet } from 'styles/breakpoints';
import PaymentDialogOrderSummary from 'pages/start/payments/components/PaymentDialogOrderSummary';
import { useCheckoutData } from 'utils/hooks';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import PaymentError from 'components/PaymentError';

interface PaymentsProps {
  ids: string[];
  selectedId: string;
  onItemClick: (id: string) => void;
  errorMessage?: string;
}

interface PaymentProps {
  title?: string;
  id: string;
  selectedId: string;
  topBorder?: boolean;
  onItemClick: (id: string) => void;
  Icon: string;
  InnerComponent?: ReactNode;
}

interface PaymentContainerProps {
  topBorder?: boolean;
}

interface KiloPaymentContainerProps {
  selected: boolean;
  isPaypal: boolean;
}

interface PaymentInnerComponentContainerProps {
  checked: boolean;
}

interface PaymentWindowLabels {
  fullName?: string;
  cardNumber?: string;
  paymentBtnTitle?: string;
  creditCardLabel?: string;
  placeholder?: string;
}

const PaymentsContainer = styled.div`
  max-width: 28.75rm;
  e #kilo-payments-error {
    .kp-error-message {
      margin-bottom: 0.375rem;
    }
  }
  @media ${tablet} {
    width: 100%;
    max-width: unset;
    margin-top: 0.375rem;
  }
`;

const PaymentItemsContainer = styled.div``;

const PaymentContainer = styled.div<PaymentContainerProps>`
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #f6f4ee;
  border-radius: 6px;
  flex-direction: column;

  // ${({ topBorder }) => topBorder && `border-top: 1px solid #E4E4EB;`}
  @media ${mobile} {
    margin-top: 0.313rem;
    padding: 1rem 1.5rem 1rem;
  }
  #primer-checkout-error-message {
    display: none;
  }
`;

const PaypalIconStyled = styled(PaypalIcon)`
  margin-left: 0.75rem;
`;

const StyledPaypalButtonIcon = styled(PaypalButtonIcon)`
  width: 4.3336rem;
  height: 1.375rem;
`;

const KiloPaymentContainer = styled.div<KiloPaymentContainerProps>`
  display: ${({ selected }) => (selected ? 'block' : 'none')};
  position: relative;

  #primer-checkout {
    .kp-row {
      display: inline;
    }

    label {
      font-family: ${({ theme }) => theme.typography.bodyM600.fontFamily};
      font-size: ${({ theme }) => theme.typography.bodyM600.fontSize};
      font-weight: 600;
      line-height: ${({ theme }) => theme.typography.bodyM600.lineHeight};
      color: ${({ theme }) => theme.colors.dark100};
      display: block;
      margin-bottom: 0.75rem;
    }

    .PrimerCheckout__formField:not(:first-of-type) {
      margin-top: 16px;
    }

    // input {
    //   font-family: ${({ theme }) => theme.typography.bodyM.fontFamily};
    //   font-size: ${({ theme }) => theme.typography.bodyM.fontSize}rem;
    //   font-weight: ${({ theme }) => theme.typography.bodyM.fontWeight};
    //   line-height: ${({ theme }) => theme.typography.bodyM.lineHeight};
    //   color: ${({ theme }) => theme.colors.dark100};
    //   box-shadow: none;
    //   border-radius: 4px;
    //   margin-bottom: 1rem;
    //   border: 1px solid ${({ theme }) => theme.colors.light100};
    //   height: 2.5rem;
    //   ::placeholder {
    //     color: ${({ theme }) => theme.colors.dark40};
    //   }
    // }
    #primer-checkout-card-cvv-field {
      margin-top: unset;
    }

    .PrimerCheckout__formInput {
      height: 2.4855rem;
      border-radius: 0.25rem;
    }

    .PrimerCheckout__label {
      font-weight: 600;
      font-size: 0.875rem;
    }

    .HostedInput__StyledInputContainer-sc-ppwut8-1 {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(228, 228, 235);
      height: 2.4855rem;
    }

    #primer-checkout-cardholder-name-field .InputContainer-sc-1kjhytk-0.khsPUc {
      height: unset;
    }

    #primer-checkout-card-cardholder-name-input {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(228, 228, 235) !important;
    }

    #kp-stripe-card {
      box-shadow: none;
      border-radius: 4px;
      margin-bottom: 1.5rem;
      border: 1px solid ${({ theme }) => theme.colors.light100};
    }

    #primer-checkout-submit-button {
      width: 100%;
      height: 3rem;
      border-radius: 0.5rem;
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
        0px 2px 4px rgba(96, 97, 112, 0.16);
      background-color: ${({ theme }) => theme.colors.primary};
      margin: 0 auto;

      span:first-child {
        display: none;
      }

      .kp-button--text {
        font-family: ${({ theme }) => theme.typography.body600.fontFamily};
        font-size: ${({ theme }) => theme.typography.body600.fontSize};
        font-weight: ${({ theme }) => theme.typography.body600.fontWeight};
        line-height: ${({ theme }) => theme.typography.body600.lineHeight};
        &:before {
          color: ${({ theme }) => theme.colors.light0};
          text-indent: 0;
          display: block;
          margin-top: 0.25rem;
          line-height: initial;
        }
      }
    }
  }
`;

const PaymentInnerComponentContainer = styled.div<PaymentInnerComponentContainerProps>`
  width: 100%;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  margin: 1rem 0;
`;

const PaypalLoaderWrapper = styled.div`
  position: absolute;
  top: 55.5%;
  left: 50.1%;
  transform: translate(-50%, -50%);
`;

const PaypalButton = styled.div`
  background: ${({ theme }) => theme.colors.paypalBackground};
  border-radius: 1.4375rem;
  width: 21.5rem;
  height: 2.9rem;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: not-allowed;

  @media ${mobile} {
    width: 17.438rem;
  }

  @media (max-width: 20.8125rem) {
    width: 89.5vw;
    height: 2.1875rem;
  }

  @media (max-width: 14.625rem) {
    width: 85vw;
    height: 1.5625rem;
  }
`;

const PaymentWrap = styled.div`
  padding-bottom: 0.5rem;
`;

const IconWrap = styled.div`
  margin-left: 0.5rem;
`;

const PaymentTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Payment: FC<PaymentProps> = ({
  title,
  id,
  selectedId,
  topBorder,
  onItemClick,
  Icon,
  InnerComponent,
}) => {
  const paymentWindowLabels: PaymentWindowLabels = useSelector(
    (state: AppState) => state?.config?.checkout?.paymentWindowLabels,
  );

  const checked = id === selectedId;

  const handleItemClick = () => {
    onItemClick(id);
  };
  const allInputLabelElements = document.querySelectorAll('.kp-input--label');
  const placeHolder = document.getElementById('kp-stripe-full-name') || null;
  useEffect(() => {
    if (allInputLabelElements?.[0] && allInputLabelElements?.[1]) {
      allInputLabelElements[0].innerHTML =
        paymentWindowLabels?.fullName || 'Full name';
      placeHolder.placeholder = paymentWindowLabels.placeholder || 'John Doe';
    }
  }, [
    allInputLabelElements,
    paymentWindowLabels?.fullName,
    paymentWindowLabels?.cardNumber,
  ]);

  return (
    <>
      <PaymentContainer topBorder={topBorder} onClick={handleItemClick}>
        <PaymentTypeContainer>
          <RadioInput checked={checked} />
          {/* ? Getting an error when trying to style Text component */}
          {!!title && (
            <Text
              type="bodyM"
              style={{ flex: 1, margin: '0 0.75rem', lineHeight: '19.6px' }}
            >
              {title}
            </Text>
          )}
          <IconWrap>
            <Icon />
          </IconWrap>
        </PaymentTypeContainer>
        {!!InnerComponent && (
          <PaymentInnerComponentContainer checked={checked}>
            {InnerComponent}
          </PaymentInnerComponentContainer>
        )}
      </PaymentContainer>
    </>
  );
};

const Payments: FC<PaymentsProps> = ({
  ids,
  selectedId,
  onItemClick,
  errorMessage,
}) => {
  const { selected_plan } = useSelector((state: AppState) => state.user);

  const renderKiloPayments = () => (
    <>
      <KiloPaymentContainer
        selected={selectedId === PAYMENT_METHOD.paypal}
        isPaypal={selectedId === PAYMENT_METHOD.paypal}
      >
        <Divider />
        <div id={PAYMENT_METHOD.paypal} />
        <PaypalLoaderWrapper>
          <StyledPaypalButtonIcon />
        </PaypalLoaderWrapper>
      </KiloPaymentContainer>
    </>
  );

  const { data } = useCheckoutData();
  const renderPayments = () =>
    ids.map((id, index) => {
      const topBorder = index + 1 === ids.length;

      switch (id) {
        case PAYMENT_METHOD.paypal:
          return (
            <PaymentWrap>
              <Payment
                title="Paypal"
                key={id}
                id={id}
                selectedId={selectedId}
                topBorder={topBorder}
                onItemClick={onItemClick}
                Icon={PaypalIconStyled}
                InnerComponent={
                  <>
                    {renderKiloPayments()}
                    {selectedId === PAYMENT_METHOD.paypal ? (
                      <>
                        <PaymentDialogOrderSummary
                          subItems={data?.orderSummarySubItems}
                          selectedProduct={selected_plan}
                        />{' '}
                      </>
                    ) : null}
                  </>
                }
              />
            </PaymentWrap>
          );
        case PAYMENT_METHOD.stripe:
          return (
            <PaymentWrap>
              <Payment
                key={id}
                title="Credit or Debit Card"
                id={id}
                selectedId={selectedId}
                topBorder={topBorder}
                onItemClick={onItemClick}
                Icon={PaymentMethodsIcon}
                InnerComponent={
                  <>
                    <KiloPaymentContainer
                      selected={selectedId === PAYMENT_METHOD.stripe}
                      isPaypal={selectedId === PAYMENT_METHOD.paypal}
                    >
                      <Divider />
                      <div id={PAYMENT_METHOD.stripe} />
                      <PaymentError errorMessage={errorMessage} />
                    </KiloPaymentContainer>
                    {selectedId === PAYMENT_METHOD.stripe ? (
                      <>
                        {' '}
                        <PaymentDialogOrderSummary
                          subItems={data?.orderSummarySubItems}
                          selectedProduct={selected_plan}
                        />{' '}
                      </>
                    ) : null}
                  </>
                }
              />
            </PaymentWrap>
          );
        default:
          return null;
      }
    });

  return (
    <PaymentsContainer>
      <div key={selectedId} id="kilo-payments-error" />
      <PaymentItemsContainer>{renderPayments()}</PaymentItemsContainer>
      {/* {renderKiloPayments()} */}
    </PaymentsContainer>
  );
};

export default Payments;
