import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  useMemo,
} from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import type { Color } from 'utils/theme';
import { CircularProgress } from '@material-ui/core';

interface PrimaryButtonProps
  extends PropsWithChildren<ComponentPropsWithoutRef<'button'>> {
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  cornerVariant?: 'default' | 'round';
  minHeight?: number;
  color?: Color;
  colorActive?: Color;
  fontSize?: number;
  loading?: boolean;
}

const Container = styled(ButtonContainer)<{
  borderRadius: number;
  active?: boolean;
  disabled?: boolean;
  minHeight?: number;
  color: Color;
  colorActive: Color;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  min-height: 3.375rem;
  padding: 0.75rem 1.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: ${({ active }) => (active ? '#65B45D' : '#3fa134')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition: 0.15s;
  border: none;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

const ButtonText = styled(Text).attrs({
  type: 'tabActive',
  color: 'light0',
})<{ fontSize?: number }>`
  color: #fff;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
`;

const CircularProgressStyled = styled(CircularProgress)`
  height: 1.25rem;
  width: 1.25rem;
`;

const PrimaryButton: FC<PrimaryButtonProps> = ({
  onClick,
  children,
  active,
  disabled,
  cornerVariant = 'default',
  minHeight,
  color = 'green',
  colorActive = 'greenDark',
  fontSize,
  loading,
  ...props
}) => {
  const borderRadius = useMemo(
    () => (cornerVariant === 'round' ? 10 : 0.5),
    [cornerVariant],
  );

  return (
    <Container
      onClick={onClick}
      as="button"
      borderRadius={borderRadius}
      active={active}
      disabled={disabled}
      minHeight={minHeight}
      color={color}
      colorActive={colorActive}
      {...props}
    >
      {loading ? (
        <CircularProgressStyled style={{ color: '#fff' }} size={20} />
      ) : (
        <ButtonText fontSize={fontSize}>{children}</ButtonText>
      )}
    </Container>
  );
};

export default React.memo(PrimaryButton);
