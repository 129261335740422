import React, { FC, useState } from 'react';
import styled from 'styled-components';
import HeaderNav from './HeaderNav';
import BurgerMenuButton from './BurgerMenuButton';
import { tablet, useQuery } from 'styles/breakpoints';
import BurgerMenu from './BurgerMenu';
import { useScrollToTop } from 'utils/hooks';
import { useRouter } from 'apis/history';
import Logo from 'assets/icons/logo.svg';
import { Quiz, QuizKey } from 'types/quiz';

interface HeaderProps {
  isMobileApp: boolean;
  bgHex?: string;
}

const Header: FC<HeaderProps> = ({ isMobileApp, bgHex }) => {
  const { isTablet } = useQuery();
  const { goToLanding } = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setIsOpen(prevState => !prevState);
    document.body.style.overflow = isOpen ? 'auto' : 'hidden';
  };

  useScrollToTop(0);

  return (
    <>
      <BurgerMenu isOpen={isOpen} toggleBurgerMenu={toggleBurgerMenu} />
      <Container bgHex={bgHex}>
        <HeaderWrapper alignCenter={isMobileApp}>
          {!isMobileApp ? (
            <>
              <LogoContainer
                onClick={() => {
                  document.body.style.overflow = 'auto';
                  goToLanding();
                }}
              >
                <LogoStyled />
              </LogoContainer>
              <NavContainer>
                <BurgerMenuButton
                  isOpen={isOpen}
                  toggleBurger={toggleBurgerMenu}
                />
              </NavContainer>
            </>
          ) : (
            <LogoContainer>
              <LogoStyled />
            </LogoContainer>
          )}
        </HeaderWrapper>
      </Container>
    </>
  );
};

export default Header;

const Container = styled.div<{ bgHex?: string }>`
  height: 4.5rem;
  position: sticky;
  display: flex;
  justify-content: center;

  align-items: center;
  padding: 0 3rem;
  z-index: 99;
  background: ${({ bgHex }) => (bgHex ? bgHex : '#fff')};
  border-bottom: ${({ bgHex }) => (bgHex ? 'none' : '1px solid #f0f0f0')};
  top: 0;
  @media ${tablet} {
    height: 4rem;
    padding: 0 1rem;
  }
`;

const HeaderWrapper = styled.div<{ alignCenter?: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${({ alignCenter }) =>
    alignCenter ? 'center' : 'space-between'};
  align-items: center;
  width: 100%;
  max-width: 84rem;
`;

const LogoStyled = styled(Logo)`
  height: 1.625rem;
  @media ${tablet} {
    height: 1.5rem;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const LogoContainer = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
