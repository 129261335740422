import React, { ComponentPropsWithoutRef } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile } from 'styles/breakpoints';
import RightArrow from 'assets/icons/quiz/right-arrow.svg';
import Checked from 'assets/icons/quiz/checked-circle.svg';
import Empty from 'assets/icons/quiz/empty-circle.svg';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  isMultiple?: boolean;
}

// border-radius: 0.75rem;
// border: 1px solid rgba(119, 119, 119, 0.12);
// background: var(--Beige, #F6F4EE);
// box-shadow: 1px 1px 4px 0px rgba(119, 119, 119, 0.10);

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
}>`
  border-radius: 0.75rem;
  background: ${({ active }) => (active ? '#E1FCDA' : '#F6F4EE')};
  border: 1px solid
    ${({ active }) => (active ? '#3FA134' : 'rgba(119, 119, 119, 0.12)')};
  box-shadow: ${({ active }) =>
    active
      ? '1px 1px 4px 0px rgba(119, 119, 119, 0.10)'
      : '0px 2px 2px 0px rgba(0, 0, 0, 0.05)'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem;
  height: 4rem;

  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};

  border-radius: 0.75rem;
  padding: 1rem;
  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
  ${({ active }) =>
    active && 'box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);'}
`;

const ButtonText = styled(Text)`
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  isMultiple,
  ...props
}) => (
  <Container
    onClick={onClick}
    active={active}
    maxSelected={maxSelected}
    as="button"
    {...props}
  >
    <ButtonText> {children}</ButtonText>
    {isMultiple && active && <Checked />}
    {isMultiple && !active && <Empty />}
    {!isMultiple && <RightArrow />}
  </Container>
);

export default React.memo(QuizAnswerButton);
