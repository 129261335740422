import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Question, Quiz, QuizAnswer, QuizKey } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import Disclaimer from './Disclaimer';
import LandingLayout from './LandingLayout';
import LCFooter from 'components/LCFooter';
import { useScrollToTop } from 'utils/hooks';
import Arrow from 'assets/icons/arrow-right.svg';
import { act } from 'react-dom/test-utils';
import BackGroundImage from '../../../../assets/images/landing/landing.png';
import BackGroundImageMobile from '../../../../assets/images/landing/landing-mobile.png';
import Logo from 'assets/icons/logo-white.svg';
import Header from 'components/reviews/Header';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import PRFooter from 'components/PRFooter';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
}

interface ButtonsContainerProps {
  length: number;
}
const easing = 'cubic-bezier(0.42, 0, 0.58, 1)';
const smallScroll = keyframes`
  from {
    transform: translateY(150px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const bigScroll = keyframes`
  from {
    transform: translateY( 200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Title = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.75rem;
  span {
    display: block;
  }
`;

const Subtitle = styled.p`
  color: #555770;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  padding-bottom: 1.75rem;
  @media ${tablet} {
    padding-bottom: 1.5rem;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 30.75rem;
  width: 100%;
  margin: 0 auto;
  padding: 2.25rem 1rem;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  row-gap: 1.5rem;
  width: 100%;
  animation: ${bigScroll} 300ms ${easing}0s,
    ${show} 500ms ${easing} 0s backwards;
  @media ${tablet} {
    gap: 0.563rem;
    row-gap: 1.25rem;
    max-width: 21.4375rem;
  }
`;

const Button = styled.div<{ active?: boolean }>`
  border-radius: 1.00581rem;
  border: none;
  background: #f6f4ee;
  width: 14rem;
  height: 15.125rem;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  @media ${tablet} {
    height: 11.25rem;
    width: 10.25rem;
  }
`;

const ButtonText = styled(Text)`
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  background: #3fa134;
  height: 3.41463rem;
  width: 14rem;
  color: #fff;
  text-align: center;
  padding: 0.76831rem 1.02438rem;
  font-size: 1.36588rem;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.375rem;
  position: absolute;
  svg {
    width: 1.53656rem;
    height: 1.53656rem;
  }
  bottom: 0;

  @media ${tablet} {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 2.5rem;
    padding: 0.5625rem 0.75rem;
    width: 10.25rem;
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;

const StyledDynamicImage = styled(DynamicImage)<{ isScaled: boolean }>`
  width: 10.92681rem;
  height: 10.92681rem;
  position: absolute;
  top: 1rem;
  right: 50%;
  transform: translateX(50%) scale(${({ isScaled }) => (isScaled ? 1.1 : 1)});
  transition: transform 0.6s ease;
  pointer-events: none;

  @media ${tablet} {
    width: 8rem;
    height: 8rem;
  }
`;

const LoadingDiv = styled.div`
  height: 100vh;
  width: 100vw;
  &:before {
    content: '';
    background: url(${BackGroundImage});
    background-size: cover;
    background-position: right;
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
  }
  @media ${tablet} {
    &:before {
      background: url(${BackGroundImageMobile});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;
    }
  }
`;

const LoadingTitle = styled(Text)`
  color: #fff;
  text-align: center;
  text-shadow: 0px 6.229px 6.229px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  font-style: normal;
  font-weight: 900;
  line-height: 3.89313rem;
  padding: 1.5rem 0 7.125rem;
  @media ${tablet} {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 2rem;
    line-height: 2.5rem;
    padding: 0.75rem 1rem 3rem;
  }
`;

const StyledLogo = styled(Logo)`
  width: 7.375rem;
  height: 1.5rem;
`;

const LoadingContainer = styled.div`
  display: flex;
  max-width: 21.4375rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const LoadingText = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const Percent = styled(Text)`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  @media ${tablet} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const LoadingInner = styled.div`
  padding: 8.375rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 45.875rem;
  width: 100%;
  margin: 0 auto;
`;

const LoaderDiv = styled.div`
  width: 100%;
  height: 1rem;
  border-radius: 6.25rem;
  background: rgb(255, 255, 255, 0.6);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const InnerLoaderDiv = styled.div.attrs(props => ({
  style: {
    width: `${props.width}%`,
  },
}))`
  position: absolute;
  height: 1rem;
  transition: width 0.1s ease-in-out;
  background-color: #3fa134;
`;

const Landing: FC<LandingProps> = ({ question, onOptionSelect }) => {
  const [hoveredButtonIndex, setHoveredButtonIndex] = useState<number | null>(
    null,
  );
  const [clickedButtonIndex, setClickedButtonIndex] = useState<number | null>(
    null,
  );
  const [active, setActive] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [showLanding, setShowLanding] = useState(false);
  const { isMobile } = useQuery();
  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;
  const isAddsFlow = location.pathname.includes('start') || quiz !== Quiz.Main;
  const quiz_answers = useSelector(
    (state: AppState) => state.user.quiz_answers,
  );

  useScrollToTop(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (Object.keys(quiz_answers).length) {
      setShowLanding(true);
    }
    if (progress === 100) {
      const timeout = setTimeout(() => {
        setShowLanding(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [progress]);

  const handleOptionClick = (value: string, label: string, index: number) => {
    setActive(value);
    setClickedButtonIndex(index); // Set clicked state
    setTimeout(() => {
      onOptionSelect({
        [question?.key]: value,
        label: label,
      });
      setClickedButtonIndex(null); // Reset clicked state after animation
    }, 500); // Adjust timeout to match scale duration if needed
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleBurgerMenu = () => {
    setIsOpen(prevState => !prevState);
  };

  useEffect(() => {
    if (quiz === Quiz.Main) {
      setShowLanding(true);
    }
  });
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);
  return (
    <>
      {showLanding ? (
        <>
          <Header isMobileApp={isAddsFlow} />
          <ContentContainer isFullHeight={isAddsFlow}>
            <InnerContainer>
              <TopContainer>
                <Title dangerouslySetInnerHTML={{ __html: question?.label }} />
                <Subtitle>{question?.optionsLabel}</Subtitle>
              </TopContainer>
              <ButtonsContainer>
                {question.options?.map((option, index) => (
                  <Button
                    key={index}
                    onClick={() =>
                      handleOptionClick(option?.value, option?.label, index)
                    }
                    onMouseEnter={() => setHoveredButtonIndex(index)}
                    onMouseLeave={() => setHoveredButtonIndex(null)}
                    active={option.value === active}
                  >
                    <StyledDynamicImage
                      src={option?.image as string}
                      alt=""
                      isScaled={
                        hoveredButtonIndex === index ||
                        clickedButtonIndex === index
                      } // Scale on hover or click
                    />
                    <ButtonText>
                      {option.label} <Arrow />
                    </ButtonText>
                  </Button>
                ))}
              </ButtonsContainer>
            </InnerContainer>
          </ContentContainer>
          <Footers>
            {isAddsFlow ? (
              <PRFooter
                disclaimerText={question?.disclaimerParagraphsPR || []}
              />
            ) : (
              <LCFooter disclaimerText={question?.disclaimerParagraphs} />
            )}
          </Footers>
        </>
      ) : (
        <LoadingDiv>
          <LoadingInner>
            <StyledLogo />
            <LoadingTitle>{question?.loadingTitle}</LoadingTitle>
            <LoadingContainer>
              <LoadingText>{question?.loadingText}</LoadingText>
              <LoaderDiv>
                <InnerLoaderDiv width={progress} />
              </LoaderDiv>
              <Percent>{progress}%</Percent>
            </LoadingContainer>
          </LoadingInner>
        </LoadingDiv>
      )}
    </>
  );
};

export default Landing;
const Footers = styled.section`
  animation: ${bigScroll} 300ms ease-in 0s, ${show} 500ms ease-in 0s backwards;
`;

const TopContainer = styled.section`
  display: flex;
  flex-direction: column;
  animation: ${smallScroll} 300ms ease-out 0s, ${show} 500ms ease-out 0s;
`;

const ContentContainer = styled.div<{ isFullHeight: boolean }>`
  min-height: ${({ isFullHeight }) =>
    isFullHeight ? ' calc(100vh - 4.5rem)' : 'calc(100vh - 225px)'};
  @media ${tablet} {
    ${({ isFullHeight }) => isFullHeight && 'min-height: calc(100vh - 4rem);'}
  }
`;

const BottomTerms = styled(Text)`
  color: rgba(119, 119, 119, 0.6);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  padding-top: 2rem;
`;

const Highlight = styled.span`
  color: #777;
  color: rgba(119, 119, 119, 0.6);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  cursor: pointer;
`;
